import EVENT_NAMES from './event-names';

function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		var error = new Error(response.statusText);
		error.response = response;
		throw error;
	}
}

export default class PageLoader {
	constructor(pageParser, pageCache) {
		this.pageParser = pageParser;
		this.pageCache = {};
	}

	loadPage(url) {
		return new Promise((resolve, reject) => {
			if (this.pageCache[url]) {
				resolve(this.pageCache[url]);
			} else {
				this.load(url)
					.then(this.createPageLoadCompleteHandler(url, resolve))
					.catch(e => (window.location.href = url));
			}
		});
	}

	createPageLoadCompleteHandler(url, resolve) {
		return content => {
			try {
				const result = this.pageParser.parsePage(content);
				result.url = url;
				this.pageCache[url] = result;
				resolve(result);
			} catch (e) {
				window.location.href = url;
			}
		};
	}

	load(url) {
		return fetch(url)
			.then(checkStatus)
			.then(response => response.text());
	}
}
