import IMAGE_SIZES from '../utils/image-sizes';

export default class ResponsiveImagesManager {
	constructor() {}

	setNode(node) {
		this.images = [...node.querySelectorAll('[data-is-responsive-image]')].map(function(img) {
			return {
				node: img,
				[IMAGE_SIZES.SMALL]: img.getAttribute('src'),
				[IMAGE_SIZES.MEDIUM]:
					img.getAttribute('data-medium-src') || img.getAttribute('data-large-src'),
				[IMAGE_SIZES.LARGE]: img.getAttribute('data-large-src'),
			};
		});
	}

	update(size) {
		this.images.forEach(function(img) {
			img.node.setAttribute('src', img[size]);
		});
	}
}
