import Events from 'minivents';
import EVENT_NAMES from './event-names';

export default class LinksManager {
	constructor(doc, contentWrapper) {
		Events(this);
		this.contentWrapper = contentWrapper;
		this.clickHandler = this.handleClick.bind(this);

		const permanentLinks = [...doc.querySelectorAll('a')].filter(
			link => !contentWrapper.contains(link)
		);

		this.addClickHandler(permanentLinks);
	}

	setCurrentLocation(url) {
		this.currentLocation = url;
		this.setupPageLinks();
	}

	setupPageLinks() {
		this.pageLinks = [...this.contentWrapper.querySelectorAll('a')];
		this.addClickHandler(this.pageLinks);
	}

	removePageLinks() {
		this.removeClickHandler(this.pageLinks);
	}

	handleClick(e) {
		let target = e.target;

		while (!target.hasAttribute('href') && target !== this.contentWrapper) {
			target = target.parentNode;
		}

		if (target.tagName.toLowerCase() === 'a') {
			if(target.getAttribute('data-disallow-click')){
				e.preventDefault();
				return;
			}

			let disableNav = target.getAttribute('target') === '_blank' || target.getAttribute('data-disable-nav') === 'true';
			if (!disableNav) {
				e.preventDefault();
				const url = target.getAttribute('href');
				if (url !== this.currentLocation) {
					this.emit(EVENT_NAMES.LINK_CLICK, {
						url: url,
					});
				}
			}
		}
	}

	addClickHandler(links) {
		links.forEach(link => {
			if (link.getAttribute('rel') === 'external') {
				link.setAttribute('target', '_blank');
			} else {
				link.addEventListener('click', this.clickHandler);
			}
		});
	}

	removeClickHandler(links) {
		links.forEach(link => link.removeEventListener('click', this.clickHandler));
	}
}
