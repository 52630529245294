import '../css/common.pcss';
import 'unfetch/polyfill/index';
import '@babel/polyfill';

import App from './app';
import Menu from './menu';
import LanguageSelector from './language-selector';
import PageParser from './core/page-parser';
import HeaderManager from './core/header-manager';
import getIsTouchDevice from './utils/get-is-touch-device';
import CookiesWarning from './core/cookies-warning';
import Footer from './footer';

const contentWrapperSelector = 'main';
const commonDataAttribute = 'data-is-common';
const navVisibleClass = 'nav-visible';
const navActiveLinkClass = 'active';
const header = document.querySelector('body > header');
const opaqueHeaderClass = 'opaque';
const opaqueHeaderOffset = 30;
const languageSelectorOpenClass = 'menu--lang-visible';

const cookiesWarningWrapper = document.querySelector('.cookies--warning--wrapper');
const cookiesWarningAcceptButton = cookiesWarningWrapper.querySelector('.button');
const cookiesWarningCloseButton = cookiesWarningWrapper.querySelector('.close--button');
const cookiesWarningVisibleClass = 'visible';
const cookiesWarningStorageKey = 'jchevalier-cw';

export default (function() {
	const html = document.querySelector('html');
	if (getIsTouchDevice()) {
		html.classList.add('touch');
	} else {
		html.classList.add('no-touch');
	}

	const doc = document;
	const contentWrapper = document.querySelector(contentWrapperSelector);
	const pageParser = new PageParser(document, contentWrapperSelector, commonDataAttribute);

	const menu = new Menu({
		node: document.querySelector('.main-navigation'),
		visibilityTrigger: document.querySelector('header [data-is-menu-trigger]'),
		classWrapper: document.querySelector('body'),
		overlay: document.querySelector('.main-navigation-overlay'),
		navVisibleClass,
		navActiveLinkClass,
	});

	const headerManager = new HeaderManager(header, opaqueHeaderClass, opaqueHeaderOffset);

	const languageSelector = new LanguageSelector({
		node: document.querySelector('.menu--lang'),
		languageSelectorOpenClass,
		header,
	});

	const cookiesWarning = new CookiesWarning({
		wrapper: cookiesWarningWrapper,
		acceptButton: cookiesWarningAcceptButton,
		closeButton: cookiesWarningCloseButton,
		visibleClass: cookiesWarningVisibleClass,
		storageKey: cookiesWarningStorageKey,
	});

	const footer = new Footer(document.querySelector('footer'));

	window.APP = new App({
		doc,
		contentWrapper,
		pageParser,
		menu,
		headerManager,
		languageSelector,
		cookiesWarning,
		footer
	});
})();
