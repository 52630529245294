const cssVendorPrefixes = ['Moz', 'Webkit', 'ms', 'O'];
const transEndEventNames = {
	WebkitTransition: 'webkitTransitionEnd',
	MozTransition: 'transitionend',
	OTransition: 'oTransitionEnd',
	msTransition: 'MSTransitionEnd',
	transition: 'transitionend',
};

// const styleTest = document.documentElement.style;
const styleTest = document.createElement('div').style;
const getCssProperty = function(property) {
	if (styleTest[property] !== undefined) {
		return property;
	} else {
		var property = property.substring(0, 1).toUpperCase() + property.substring(1);

		for (var i = 0, total = cssVendorPrefixes.length; i < total; i++) {
			let prefix = cssVendorPrefixes[i];
			let vendorProperty = prefix + property;
			if (styleTest[vendorProperty] !== undefined) {
				return vendorProperty;
			}
		}
	}
};

let CSS_TRANSITION_END_EVENT;

const CSS_TRANSFORM = getCssProperty('transform');
const CSS_TRANSITION_DELAY = getCssProperty('transition-delay');
const CSS_TRANSITION_DURATION = getCssProperty('transition-duration');
const CSS_ANIMATION_DELAY = getCssProperty('animation-delay');
const CSS_ANIMATION_DURATION = getCssProperty('animation-duration');

for (name in transEndEventNames) {
	if (styleTest[name] !== undefined) {
		CSS_TRANSITION_END_EVENT = transEndEventNames[name];
	}
}

const animationEndEventNames = {
	animation: 'animationend',
	OAnimation: 'oAnimationEnd',
	MozAnimation: 'animationend',
	WebkitAnimation: 'webkitAnimationEnd',
};

let CSS_ANIMATION_END_EVENT;

for (name in animationEndEventNames) {
	if (styleTest[name] !== undefined) {
		CSS_ANIMATION_END_EVENT = animationEndEventNames[name];
	}
}

export {
	CSS_TRANSFORM,
	CSS_TRANSITION_DELAY,
	CSS_TRANSITION_DURATION,
	CSS_TRANSITION_END_EVENT,
	CSS_ANIMATION_DELAY,
	CSS_ANIMATION_DURATION,
	CSS_ANIMATION_END_EVENT,
};
