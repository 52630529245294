import Events from 'minivents';
import EVENT_NAMES from './core/event-names';

export default class Menu {
	constructor(config) {
		Events(this);
		this.node = config.node;
		this.visibilityTrigger = config.visibilityTrigger;
		this.classWrapper = config.classWrapper;
		this.navVisibleClass = config.navVisibleClass;
		this.navActiveLinkClass = config.navActiveLinkClass;

		this.links = [...this.node.querySelectorAll('a')];

		const clickListener = this.handleClick.bind(this);
		this.visibilityTrigger.addEventListener('click', clickListener);
		config.overlay.addEventListener('click', clickListener);

		this.keyHandler = this.handleKey.bind(this);
	}

	updateActiveLink(newLinks){
		this.links.forEach((node, index) => {
			const newLink = newLinks[index];

			if(newLink.active){
				node.classList.add('active');
			}
			else{
				node.classList.remove('active');
			}
		});
	}

	updateContent(newLinks){
		this.links.forEach((node, index) => {
			const newLink = newLinks[index];
			node.setAttribute('href', newLink.href);
			node.textContent = newLink.text;

			if(newLink.active){
				node.classList.add('active');
			}
			else{
				node.classList.remove('active');
			}
		});
	}

	handleClick(e) {
		if (e) {
			e.preventDefault();
		}

		this.classWrapper.classList.toggle(this.navVisibleClass);
		const open = this.classWrapper.classList.contains(this.navVisibleClass);

		if (open) {
			document.addEventListener('keyup', this.keyHandler);
		} else {
			document.removeEventListener('keyup', this.keyHandler);
		}

		this.emit(EVENT_NAMES.MENU_TOGGLE, {
			open,
		});
	}

	handleKey(e) {
		if (e.keyCode === 27) {
			this.close();
		}
	}

	close() {
		if (this.classWrapper.classList.contains(this.navVisibleClass)) {
			this.handleClick();
		}
	}
}
