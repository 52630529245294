import getIsTouchDevice from './get-is-touch-device';

export const EVENT_TYPES = getIsTouchDevice()
	? {
			start: 'touchstart',
			move: 'touchmove',
			end: 'touchend',
			click: 'touchend',
	  }
	: {
			start: 'mousedown',
			move: 'mousemove',
			end: 'mouseup',
			click: 'click',
	  };
