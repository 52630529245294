export default class HeaderManager {
	constructor(node, opaqueClass, offset) {
		this.nodeClassList = node.classList;
		this.opaqueClass = opaqueClass;
		this.offset = offset;
		this.canUpdateOnScroll = true;
	}

	updateToScroll(scrollTop) {
		if(!this.canUpdateOnScroll){
			return;
		}

		if (scrollTop > this.offset && !this.nodeClassList.contains(this.opaqueClass)) {
			this.makeOpaque(true);
		}

		if (scrollTop < this.offset && this.nodeClassList.contains(this.opaqueClass)) {
			this.makeOpaque(false);
		}
	}

	makeOpaque(make) {
		if (make) {
			this.nodeClassList.add(this.opaqueClass);
		} else {
			this.nodeClassList.remove(this.opaqueClass);
		}
	}
}
