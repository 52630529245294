import { EVENT_TYPES } from './utils/device-events';
import getBodySize from './utils/get-body-size';
import getIsTouchDevice from './utils/get-is-touch-device';

export default class LanguageSelector {
	constructor(config) {
		this.node = config.node;
		this.openClass = config.languageSelectorOpenClass;
		this.header = config.header;

		this.body = document.querySelector('body');

		this.keyHandler = this.handleKey.bind(this);
		this.closeHandler = this.handleClose.bind(this);

		this.links = [...this.node.querySelectorAll('a')];

		this.selectedLink = [...this.node.querySelectorAll('li')]
			.find(function(li){
				return li.classList.contains('selected');
			})
			.querySelector('a');

		this.currentLang = this.selectedLink.getAttribute('data-lang');

		if (getIsTouchDevice()) {
			this.node.addEventListener(EVENT_TYPES.click, this.handleClick.bind(this));
			this.selectedLink.setAttribute('data-disallow-click', 'true');
		}


	}

	updateLinks(newLinks){
		let newLang;

		this.selectedLink.removeAttribute('data-disallow-click');

		this.links.forEach((node) => {
			const nodeLang = node.getAttribute('data-lang');
			const newLink = newLinks[nodeLang];

			node.setAttribute('href', newLink.href);
			if(newLink.selected){
				newLang = nodeLang;
				this.selectedLink = node;
				node.parentNode.classList.add('selected');
			}
			else{
				node.parentNode.classList.remove('selected');
			}
		});

		this.selectedLink.setAttribute('data-disallow-click', 'true');

		if(this.currentLang !== newLang){
			this.currentLang = newLang;
			return true;
		}
		else{
			return false;
		}
	}

	setAjaxNavigation(enable){
		this.links.forEach(function(link){
			if(enable){
				link.removeAttribute('data-disable-nav');
			}
			else{
				link.setAttribute('data-disable-nav', 'true');
			}
		})
	}

	handleClick(e) {
		// Esperamos un momento para que tengamos tiempo a capturar el click en el enlace
		setTimeout(() => {
			const classList = this.header.classList;
			classList.toggle(this.openClass);

			const open = classList.contains(this.openClass);

			const isTouchMode = getIsTouchDevice();

			if (open) {
				e.preventDefault();

				document.addEventListener('keyup', this.keyHandler);
				setTimeout(() => {
					this.body.addEventListener(EVENT_TYPES.click, this.closeHandler);
				}, 5);

				if (isTouchMode) {
					const height = this.node.getBoundingClientRect().height + 25;
					this.header.style.minHeight = `${height}px`;
				}

				return false;
			}
			else {
				this.body.removeEventListener(EVENT_TYPES.click, this.closeHandler);
				document.removeEventListener('keyup', this.keyHandler);

				if (isTouchMode) {
					this.header.style.minHeight = 0;
				}
			}
		}, 10);
	}

	handleClose(e) {
		const hasToClose = !this.node.contains(e.target);
		if (hasToClose) {
			this.close();
		}
	}

	handleKey(e) {
		if (e.keyCode === 27) {
			this.close();
		}
	}

	close() {
		if (this.header.classList.contains(this.openClass)) {
			this.handleClick();
		}
	}
}
