export default class PageParser {
	constructor(doc, contentWrapperSelector, commonDataAttr) {
		this.doc = doc;
		this.contentWrapperSelector = contentWrapperSelector;
		this.commonDataAttr = commonDataAttr;
	}

	setModuleSupport(supported) {
		this.modulesSupported = supported;
	}

	parsePage(pageHtml) {
		const html = document.createElement('html');
		html.innerHTML = pageHtml;

		const head = html.querySelector('head');
		const body = html.querySelector('body');

		const content = body.querySelector(this.contentWrapperSelector).innerHTML;

		const style = [...head.querySelectorAll('link')]
			.filter(
				node =>
					node.getAttribute('rel') === 'stylesheet' &&
					!node.hasAttribute(this.commonDataAttr)
			)
			.map(node => node.getAttribute('href'));

		const inlineStyle = [...head.querySelectorAll('style')];

		const script = [...body.querySelectorAll('script')]
			.filter(node => !node.hasAttribute(this.commonDataAttr))
			.filter(node => {
				return (
					(node.getAttribute('type') === 'module') === this.modulesSupported ||
					node.hasAttribute('data-is-ga')
				);
			})
			.map(node => {
				const src = node.getAttribute('src');
				const isAsync = node.hasAttribute('async');
				const content = node.textContent;
				const isGAScript = node.hasAttribute('data-is-ga');
				return { src, isAsync, content, isGAScript };
			});

		const title = head.querySelector('title').textContent;
		const pageClasses = body.className;
		const pageUrl = head.querySelector('meta[name="jc:url"]').getAttribute('content');

		const languageLinks = [...body.querySelectorAll('.menu--lang li')]
			.reduce(function(acc, li){
				const link = li.querySelector('a');
				acc[link.getAttribute('data-lang')] = {
					href: link.getAttribute('href'),
					selected: li.classList.contains('selected')
				}
				return acc;
			}, {})

		const navigationLinks = [...body.querySelectorAll('.main-navigation a')]
			.map(function(link){
				return{
					text: link.textContent,
					href: link.getAttribute('href'),
					active: link.classList.contains('active')
				}

			});

		const footer = body.querySelector('footer').innerHTML;

		const cookiesWarningWrapper = body.querySelector('.cookies--warning--wrapper');
		const cookiesWarning = {
			title: cookiesWarningWrapper.querySelector('h2').textContent,
			text: cookiesWarningWrapper.querySelector('p').innerHTML,
			button: cookiesWarningWrapper.querySelector('.button').textContent
		};

		return {
			content,
			style,
			inlineStyle,
			script,
			title,
			pageClasses,
			pageUrl,
			languageLinks,
			navigationLinks,
			footer,
			cookiesWarning
		};
	}

	cleanCurrentPage() {
		this.doc.querySelector(this.contentWrapperSelector).innerHTML = '';

		[...this.doc.querySelector('head').querySelectorAll('link, style')]
			.concat([...this.doc.querySelector('body').querySelectorAll('script')])
			.forEach(node => {
				if (!node.hasAttribute(this.commonDataAttr)) {
					node.parentNode.removeChild(node);
				}
			});
	}
}
