import Events from 'minivents';
import EVENT_NAMES from './event-names';

export default class HistoryManager {
	constructor() {
		Events(this);

		window.addEventListener('popstate', this.handlePopstate.bind(this));
	}

	handlePopstate(e) {
		if (e.state) {
			this.emit(EVENT_NAMES.HISTORY_BACK_REQUEST, e.state);
		}
	}

	addPage(page) {
		window.history.pushState(
			{
				name: page.name,
				url: page.url,
			},
			null,
			page.url
		);
	}
}
