import LinksManager from './core/links-manager';
import HistoryManager from './core/history-manager';
import PageLoader from './core/page-loader';
import ResponsiveImagesManager from './core/responsive-images';

import { CSS_TRANSITION_END_EVENT } from './utils/css-properties';
import EVENT_NAMES from './core/event-names';
import debounce from './utils/debounce';
import getBodySize from './utils/get-body-size';
import IMAGE_SIZES from './utils/image-sizes';

function buildStylesheetNode(url) {
	const node = document.createElement('link');
	node.setAttribute('rel', 'stylesheet');
	node.setAttribute('href', url);

	return node;
}

function buildScriptNode(_node) {
	const node = document.createElement('script');
	if (_node.src) {
		node.setAttribute('async', _node.isAsync);
		node.setAttribute('src', _node.src);
	} else {
		node.textContent = _node.content;
	}

	return node;
}

export default class App {
	constructor({
		doc,
		contentWrapper,
		pageParser,
		menu,
		headerManager,
		languageSelector,
		cookiesWarning,
		footer
	}) {
		this.doc = doc;
		this.contentWrapper = contentWrapper;
		this.pageParser = pageParser;
		this.menu = menu;
		this.headerManager = headerManager;
		this.languageSelector = languageSelector;
		this.cookiesWarning = cookiesWarning;
		this.footer = footer;

		this.body = doc.querySelector('body');
		this.body.classList.add('loading');

		this.firstPageLoad = true;

		this.responsiveImagesSize = IMAGE_SIZES.SMALL;

		this.newPageReadyHandler = this.handleNewPageReady.bind(this);

		this.menu.on(EVENT_NAMES.MENU_TOGGLE, this.handleMenuToggle.bind(this));

		const pageChangeRequestHandler = this.handlePageChangeRequest.bind(this);

		this.linksManager = new LinksManager(this.doc, this.contentWrapper);
		this.linksManager.on(EVENT_NAMES.LINK_CLICK, pageChangeRequestHandler);

		this.historyManager = new HistoryManager();
		this.historyManager.on(EVENT_NAMES.HISTORY_BACK_REQUEST, pageChangeRequestHandler);

		this.pageLoader = new PageLoader(this.pageParser);

		this.responsiveImagesManager = new ResponsiveImagesManager();

		this.animationFrameHandler = this.handleAnimationFrame.bind(this);

		this.resizeHandler = debounce(this.handleResize).bind(this);
		window.addEventListener('resize', this.resizeHandler);
		window.addEventListener('orientationchange', this.resizeHandler);
		this.cookiesWarning.on(EVENT_NAMES.COOKIES_WARNING_TOGGLE, this.resizeHandler);

		this.modulesSupported = USE_MODULES;
		this.pageParser.setModuleSupport(this.modulesSupported);
		this.isChangingPage = false;
	}

	setCurrentPage(page) {
		page.url = this.getPageUrl();

		this.currentPage = page;
		this.historyManager.addPage(page);

		this.responsiveImagesManager.setNode(this.contentWrapper);

		if (this.firstPageLoad) {
			this.firstPageLoad = false;
			this.enterCurrentPage();
		} else {
			// Accedemos al tracker de GA, modificamos el valor de la página y lo mandamos.
			const tName = this.getTrackerName();
			if (tName) {
				ga(`${tName}.set`, 'page', page.url);
				ga(`${tName}.send`, 'pageview');
			}
		}
	}

	enterCurrentPage() {
		const pageConfig = window.APP_PAGE_CONFIG || {};
		this.linksManager.setCurrentLocation(this.currentPage.url);
		this.currentPage.setup(this.contentWrapper, pageConfig).then(() => {
			this.handleResize();
			this.setupImageLoad();
			setTimeout(() => {
				this.contentWrapper.classList.remove('exit');
				this.contentWrapper.classList.remove('transition');

				setTimeout(() => {
					this.body.classList.remove('loading');
					this.currentPage.enter().then(() => {
						this.currentPage.init();
						this.handleResize();
						this.handleAnimationFrame();
					});
				}, 50);
			}, 50);
		});
	}

	handlePageChangeRequest(e) {
		if (this.isChangingPage) {
			return;
		}

		this.menu.close();
		this.isChangingPage = true;
		this.body.classList.add('loading');

		Promise.all([this.hideContentWrapper(), this.pageLoader.loadPage(e.url)]).then(
			this.newPageReadyHandler
		);
	}

	hideContentWrapper() {
		return new Promise((resolve, reject) => {
			const handler = e => {
				this.contentWrapper.classList.add('transition');
				this.currentPage.exit().then(() => {
					this.contentWrapper.removeEventListener(CSS_TRANSITION_END_EVENT, handler);
					resolve(this.contentWrapper);
				});
			};

			this.contentWrapper.addEventListener(CSS_TRANSITION_END_EVENT, handler);
			this.contentWrapper.classList.add('exit');
		});
	}

	handleNewPageReady([contentWrapper, newContent]) {
		// this.pageParser.cleanCurrentPage();
		window.scrollTo(0, 0);
		setTimeout(() => {
			this.isChangingPage = false;
			this.buildNewPage(newContent);
		}, 250);
	}

	buildNewPage(newContent) {
		this.getPageUrl = () => newContent.url;
		// this.contentWrapper.innerHTML = newContent.content;

		const head = this.doc.querySelector('head');
		head.querySelector('title').textContent = newContent.title;
		head.querySelector('meta[name="jc:url"]').setAttribute('content', newContent.pageUrl);

		const styles = newContent.style.map(url => this.addStyle(head, url));

		const body = this.doc.querySelector('body');
		// Añadimos todos los scripts excepto el snippet de Analytics.
		const scripts = newContent.script
			.filter(node => !node.isGAScript)
			.map(node => this.addScript(body, node));

		newContent.inlineStyle.forEach(function(tag) {
			head.appendChild(tag);
		});

		// Actualizamos los enlaces del selector de idiomas
		const languageHasChanged = this.languageSelector.updateLinks(newContent.languageLinks);


		if(languageHasChanged){
			// Si ha cambiado el idioma actualizamos el menú de navegación, el footer y el aviso de cookies
			this.footer.updateContent(newContent.footer);
			this.menu.updateContent(newContent.navigationLinks);
			this.cookiesWarning.updateContent(newContent.cookiesWarning);
		}
		else{
			// Si no ha cambiado, sólo actualizamos el enlace marcado de la navegación
			this.menu.updateActiveLink(newContent.navigationLinks);
		}

		Promise.all([...styles, ...scripts]).then(() => {
			body.className = `${newContent.pageClasses} loading`;
			this.contentWrapper.innerHTML = newContent.content;
			this.enterCurrentPage();
			// // Añadimos el script de GA
			// newContent.script
			// 	.filter(node => node.isGAScript)
			// 	.forEach(node => this.addScript(body, node));

			this.setupImageLoad();
		});
	}

	addStyle(head, url) {
		return new Promise((resolve, reject) => {
			const node = buildStylesheetNode(url);
			node.addEventListener('load', () => {
				resolve();
			});
			head.appendChild(node);
		});
	}

	addScript(body, _node) {
		return new Promise((resolve, reject) => {
			const node = buildScriptNode(_node);
			if (_node.src) {
				node.addEventListener('load', () => {
					resolve();
				});
			} else {
				resolve();
			}
			body.appendChild(node);
		});
	}

	handleResize() {
		const currentSize = getBodySize();
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		const scrollTop =
			window.scrollY != undefined ? window.scrollY : document.documentElement.scrollTop;
		const cookiesWarningHeight = this.cookiesWarning.getHeight();

		if (currentSize !== this.screenSize) {
			this.responsiveImagesManager.update(currentSize);
		}

		this.currentPage.handleResize({
			currentSize,
			windowWidth,
			windowHeight,
			scrollTop,
			cookiesWarningHeight,
		});

		this.screenSize = currentSize;
	}

	handleMenuToggle(e) {
		const isOpen = e.open;
	}

	handleAnimationFrame(t) {
		const scrollTop =
			window.scrollY != undefined ? window.scrollY : document.documentElement.scrollTop;
		this.currentPage.updateAnimation(t, scrollTop);
		this.headerManager.updateToScroll(scrollTop);
		window.requestAnimationFrame(this.animationFrameHandler);
	}

	setupImageLoad() {
		[...this.contentWrapper.querySelectorAll('img')].forEach(img => {
			const handler = () => {
				img.removeEventListener('load', handler);
				this.handleResize();
			};

			img.addEventListener('load', handler);
		});
	}

	getPageUrl() {
		return window.location.pathname;
	}

	getTrackerName() {
		try{
			const tracker = ga.getAll()[0];
			const trackerKeys = Object.keys(tracker);
			let dataHolder;
			for (let k in tracker) {
				if (tracker[k].data) {
					dataHolder = tracker[k];
				}
			}

			if (dataHolder) {
				return dataHolder.data.values[':name'];
			} else {
				return null;
			}
		}
		catch(e){
			return null;
		}
	}

}
