import Events from 'minivents';
import EVENT_NAMES from './event-names';
import { EVENT_TYPES } from '../utils/device-events';
import { CSS_TRANSITION_END_EVENT } from '../utils/css-properties';

export default class CookiesWarning {
	constructor(config) {
		Events(this);
		this.wrapper = config.wrapper;
		this.acceptButton = config.acceptButton;
		this.closeButton = config.closeButton;
		this.visibleClass = config.visibleClass;
		this.storageKey = config.storageKey;
		this.visible = false;
		this.showMessage();
	}

	updateContent(newContent){
		this.wrapper.querySelector('h2').textContent = newContent.title;
		this.wrapper.querySelector('p').innerHTML = newContent.text;
		this.wrapper.querySelector('.button').textContent = newContent.button;
	}

	showMessage() {
		// Si Safari tiene activada la opción de bloquear cookies,
		// cualquier operación contra LocalStorage lanza una excepción.
		try {
			const cookiesAccepted = window.localStorage.getItem(this.storageKey);

			if (!cookiesAccepted) {
				this.wrapper.classList.add(this.visibleClass);
				this.acceptButton.addEventListener(EVENT_TYPES.click, this.handleAccept.bind(this));
				this.closeButton.addEventListener(EVENT_TYPES.click, this.close.bind(this));
				this.visible = true;
				this.emit(EVENT_NAMES.COOKIES_WARNING_TOGGLE);
			}
		} catch (e) {}
	}

	handleAccept(e) {
		e.preventDefault();
		// Si Safari tiene activada la opción de bloquear cookies,
		// cualquier operación contra LocalStorage lanza una excepción.
		try {
			window.localStorage.setItem(this.storageKey, true);
			this.close();
		} catch (e) {}
	}

	close() {
		const handler = () => {
			this.wrapper.removeEventListener(CSS_TRANSITION_END_EVENT, handler);
			this.wrapper.parentNode.removeChild(this.wrapper);
			this.visible = false;
			this.emit(EVENT_NAMES.COOKIES_WARNING_TOGGLE);
		};

		this.wrapper.addEventListener(CSS_TRANSITION_END_EVENT, handler);
		this.wrapper.classList.remove(this.visibleClass);
	}

	getHeight() {
		return this.visible ? this.wrapper.getBoundingClientRect().height : 0;
	}
}
